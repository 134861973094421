<template>
  <div id="EditMeet">
    <b-button @click="add()" size="sm">
      <b-icon-pencil
    /></b-button>
    <div>
      <b-modal scrollable centered v-model="show" title="Enlace del Meet">
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div>
              <label for="validationServer01"><b>Enlace:</b></label>
              <b-form-input
                type="text"
                placeholder="Ingrese enlace"
                v-model="data.url"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../../../components/AlertError.vue";
export default {
  name: "EditMeet",
  components: { AlertError },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      data: { courseDetailId: this.idCourse, url: this.value },
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      this.data = {
        courseDetailId: this.idCourse,
        url: this.value,
      };
      this.show = true;
    },
    async saveAddItem() {
      if (this.data.url == "") {
        this.data.url = null;
      }
      await this.$axios
        .post(
          "/dashboard/course/" + this.idCourse + "/meeting-edit",
          this.data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>