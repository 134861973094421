<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="card">
      <div class="card-header">
        <h2><b-icon-bookmarks /> {{ desserts.programName }}</h2>
      </div>
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col-lg-8 col-sm-12">
              <h3>{{ desserts.courseName }}</h3>
            </div>

            <div class="col-lg-4 col-sm-12 text-right">
              <!--
              <div class="row" v-if="dataAccessL.classroomEdit">
                <div class="col-9">
                  <b-button
                    target="_blank"
                    :href="desserts.courseUrlClassroom"
                    variant="warning"
                    class="w-100"
                    >Ingresar al aula virtual de Classroom</b-button
                  >
                </div>
                <div class="col-3">
                  <EditClassRoom
                    :idCourse="id"
                    :value="desserts.courseUrlClassroom"
                  />
                </div>
              </div>
              <div v-else>
                <div v-if="desserts.courseUrlClassroom != null">
                  <b-button
                    target="_blank"
                    :href="desserts.courseUrlClassroom"
                    variant="warning"
                    class="w-100"
                    >Ingresar al aula virtual de Classroom</b-button
                  >
                </div>
              </div>
              -->
            </div>
          </div>
          <p>
            <em>
              {{ desserts.courseDescription }}
            </em>
          </p>
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-4 col-sm-12 mb-3">
            <b-card
              border-variant="secondary"
              header="DETALLES"
              header-border-variant="secondary"
            >
              <b-card-text class="m-1"
                ><div>
                  <div class="row">
                    <div class="col-lg-2 col-sm-1">
                      <h3><b-icon-receipt /></h3>
                    </div>
                    <div class="col-lg-10 col-sm-11">
                      <h5 class="mb-0">Sílabo del curso</h5>
                      <div v-if="curriculum(desserts.courseCurriculum)">
                        <b-link href="#">Archivo de sílabo</b-link>
                      </div>
                      <div v-else>
                        <em><p class="text-muted">No hay sílabo</p></em>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-lg-2 col-sm-1">
                      <h3><b-icon-clock-history /></h3>
                    </div>
                    <div class="col-lg-10 col-sm-11">
                      <h5 class="mb-0">Turno</h5>
                      <p>{{ desserts.courseGroup }}</p>
                      <hr />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-lg-2 col-sm-1">
                      <h3><b-icon-calendar-day /></h3>
                    </div>
                    <div class="col-lg-10 col-sm-11">
                      <h5 class="mb-0">Cronograma</h5>
                      <p class="mb-0">
                        Inicio: {{ desserts.courseSchedule.startDate }}
                      </p>
                      <p>Fin: {{ desserts.courseSchedule.finalDate }}</p>
                      <hr />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-lg-2 col-sm-1">
                      <h3><div class="fas fa-comments" /></h3>
                    </div>
                    <div class="col-lg-10 col-sm-11">
                      <div class="row" v-if="dataAccessL.meetingEdit">
                        <div class="col-8">
                          <h5 class="mb-0">Enlace de Meet</h5>
                        </div>
                        <div class="col-4">
                          <EditMeet
                            :idCourse="id"
                            :value="desserts.courseUrlMeeting"
                          />
                        </div>
                      </div>
                      <div v-else>
                        <h5 class="mb-0">Sala de reunión</h5>
                      </div>
                      <div v-if="desserts.courseUrlMeeting == null">
                        <p class="mb-0 text-muted">
                          <em>No hay enlace de meet</em>
                        </p>
                      </div>
                      <div v-else>
                        <b-link
                          target="_blank"
                          :href="desserts.courseUrlMeeting"
                          >Ingresar a la sala Meet</b-link
                        >
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                <!--temporal acceso-->
                <div v-if="dataAccessR.listAssists">
                  <div class="row">
                    <div class="col-lg-2 col-sm-1">
                      <h3><b-icon-cloud-arrow-up /></h3>
                    </div>
                    <div class="col-lg-10 col-sm-11">
                      <h5 class="mb-1">Documento de notas</h5>
                      <SendDocument
                        :idCourse="id"
                        :documentName="desserts.courseUrlGrade"
                      />
                      <b-link
                        v-if="desserts.courseUrlGrade != null"
                        target="_blank"
                        :href="
                          $urlFirebaseDocument +
                          desserts.courseUrlGrade +
                          '?alt=media'
                        "
                        >Ver documento</b-link
                      >
                      <hr />
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
          <div class="col-lg-8 col-sm-12">
            <b-card
              border-variant="secondary"
              header="CONTENIDO DEL CURSO"
              header-border-variant="secondary"
            >
              <b-card-text>
                <!--Mis asistencias-->
                <div v-show="dataAccessR.myAssists">
                  <div class="row">
                    <div class="col-1">
                      <h2><b-icon-calendar-check /></h2>
                    </div>
                    <div class="col-11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Asistencia</h5>
                        <!--<small><b-icon-three-dots /></small>-->
                      </div>

                      <p class="mb-1">
                        Enlace para la visualización de la asistencia en cada
                        uno de los días definidos en el horario.
                      </p>

                      <CourseAsistencia :idCourse="id" />
                      <hr />
                    </div>
                  </div>
                </div>
                <!--Mis notas-->
                <div v-show="dataAccessR.myGrades">
                  <div class="row">
                    <div class="col-1">
                      <h2><b-icon-card-checklist /></h2>
                    </div>
                    <div class="col-11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Lista de notas</h5>
                        <!--<small><b-icon-three-dots /></small>-->
                      </div>

                      <p class="mb-1">
                        Enlace para la visualización de las notas de este curso.
                      </p>

                      <CourseNotas :idCourse="id" />
                      <hr />
                    </div>
                  </div>
                </div>
                <!--Horarios-->
                <div v-show="dataAccessR.horary">
                  <div class="row">
                    <div class="col-1">
                      <h2><b-icon-calendar4-week /></h2>
                    </div>
                    <div class="col-11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Horario</h5>
                        <!--<small><b-icon-three-dots /></small>-->
                      </div>

                      <p class="mb-1">
                        Enlace para la visualización del horario según fechas y
                        horas del curso.
                      </p>

                      <CourseHorario :idCourse="id" />
                      <hr />
                    </div>
                  </div>
                </div>
                <!--Lista de notas-->
                <div v-show="dataAccessR.listGrades">
                  <div class="row">
                    <div class="col-1">
                      <h2><b-icon-card-list /></h2>
                    </div>
                    <div class="col-11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Lista de notas</h5>
                        <!--<small><b-icon-three-dots /></small>-->
                      </div>

                      <p class="mb-1">
                        Enlace para la visualización y registro de notas.
                      </p>

                      <!--<b-link :to="'/course/' + id + '/list-grades'"
                        >Lista de notas</b-link
                      >-->
                      <b-link :to="'/course/' + id + '/list-grades'"
                        >Registrar Notas</b-link
                      >
                      <hr />
                    </div>
                  </div>
                </div>
                <!--Lista de asistencias-->
                <div v-show="dataAccessR.listAssists">
                  <div class="row">
                    <div class="col-1">
                      <h2><b-icon-card-checklist /></h2>
                    </div>
                    <div class="col-11">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Lista de asistencias</h5>
                        <!--<small><b-icon-three-dots /></small>-->
                      </div>

                      <p class="mb-1">
                        Enlace para la visualización y registro de asistencias.
                      </p>
                      <b-link :to="'/listAssists/' + id"
                        >Ingresar asistencia</b-link
                      >
                      <hr />
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseAsistencia from "./Course-Asistencia.vue";
import CourseNotas from "./Course-Notas.vue";
import CourseHorario from "./Course-Horario.vue";
import EditMeet from "./Edit/Course-Meet..vue";
import EditClassRoom from "./Edit/Course-ClassRoom.vue";
import SendDocument from "./SendDocuments/Course-Send-Document.vue";
export default {
  components: {
    CourseAsistencia,
    CourseNotas,
    CourseHorario,
    EditMeet,
    EditClassRoom,
    SendDocument,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    //Los datos llegan asincronicamente, puede haber errores de undefinido, incializa los datos
    desserts: { courseSchedule: { startDate: "", finalDate: "" } },
    selected: "",
    dataAccessR: {},
    dataAccessL: {},
  }),

  created() {
    this.initialize();
    this.initializeAccessR();
    this.initializeAccessL();
  },

  methods: {
    curriculum(data) {
      if (data != null) return true;
      else return false;
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/dashboard/course/" + this.id, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.selected = "";
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },

    async initializeAccessR() {
      await this.$axios
        .get("/dashboard/course/" + this.id + "/access?section=R", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.dataAccessR = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async initializeAccessL() {
      await this.$axios
        .get("/dashboard/course/" + this.id + "/access?section=L", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.dataAccessL = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>