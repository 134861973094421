<template>
  <div id="SendDocument">
    <b-link @click="add()">Subir documento</b-link>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Subir documento de notas"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div>
              <label for="validationServer01"><b>Documento:</b></label>
              <b-form-file
                type="file"
                browse-text="Buscar"
                placeholder="Seleccionar documento"
                @change="previewDocument"
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="onUpload()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import AlertError from "../../../../components/AlertError.vue";
export default {
  name: "SendDocument",
  components: { AlertError },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
    documentName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      documentData: null,
      uploadValue: 0,
      showModal: false,
      nameUrlSend: null,
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      this.show = true;
    },
    previewDocument(event) {
      this.uploadValue = 0;
      this.documentData = event.target.files[0];
    },
    onUpload() {
      this.$parent.selected = "circular";
      this.show = false;
      let nameUrl = null;
      if (this.documentName == null) nameUrl = uuid.v1();
      else nameUrl = this.documentName;
      this.nameUrlSend = nameUrl;
      const storageRef = firebase
        .storage()
        //uuid genera claves unicas
        .ref("EvaluationDocuments/" + nameUrl)
        .put(this.documentData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(this.uploadValue);
        },
        (error) => {
          console.log(error.message);
          this.show = true;
          this.$parent.selected = "";
          this.$refs.error.showAlert("Problema al subir documento");
        },
        () => {
          this.uploadValue = 100;
          console.log("finalizado");
          this.saveAddItem();
          this.$parent.selected = "";
        }
      );
    },
    async saveAddItem() {
      await this.$axios
        .post(
          "/dashboard/course/" + this.idCourse + "/grades-edit",
          { courseDetailId: this.idCourse, url: this.nameUrlSend },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.documentData = null;
          this.uploadValue = 0;
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.show = true;
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>