<template>
  <div id="CourseHorario">
    <b-link @click="add()">Ver horario</b-link>
    <div>
      <b-modal scrollable centered busy v-model="show" title="Horario">
        <b-container fluid>
          <!--Contenido modal-->
          <div v-if="desserts.length != 0">
            <div class="container fondoTexto">
              <div class="row">
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Lunes</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="info"
                    v-for="(item, k) in daysHorary[0]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Martes</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="info"
                    v-for="(item, k) in daysHorary[1]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Miércoles</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="info"
                    v-for="(item, k) in daysHorary[2]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Jueves</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="info"
                    v-for="(item, k) in daysHorary[3]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Viernes</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="info"
                    v-for="(item, k) in daysHorary[4]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Sábado</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="success"
                    v-for="(item, k) in daysHorary[5]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
                <div class="col text-center border m-1">
                  <div class="mt-1">
                    <b class="text-muted">Domingo</b>
                  </div>
                  <hr />
                  <b-alert
                    show
                    variant="success"
                    v-for="(item, k) in daysHorary[6]"
                    :key="k"
                  >
                    <small
                      ><b>{{ item.startTime }} - {{ item.finalTime }}</b></small
                    >
                  </b-alert>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <em><h4 class="text-center">No hay horario</h4></em>
          </div>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseHorario",
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      desserts: [],
      fields: [
        { key: "day", label: "Dia" },
        { key: "startTime", label: "Hora de inicio" },
        { key: "finalTime", label: "Hora de fin" },
      ],
      daysHorary: [[], [], [], [], [], [], []],
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      this.daysHorary = [[], [], [], [], [], [], []];
      console.log(this.daysHorary);
      this.initialize();
      this.show = true;
    },
    async initialize() {
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/horary", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          //datos para daysHorary
          for (var i in res.data) {
            switch (res.data[i].day) {
              case "Lunes":
                this.daysHorary[0].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Martes":
                this.daysHorary[1].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Miércoles":
                this.daysHorary[2].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Jueves":
                this.daysHorary[3].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Viernes":
                this.daysHorary[4].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Sábado":
                this.daysHorary[5].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
              case "Domingo":
                this.daysHorary[6].push({
                  startTime: res.data[i].startTime,
                  finalTime: res.data[i].finalTime,
                });
                break;
            }
          }
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.fondoTexto {
  font: oblique bold 120% cursive;
}
</style>