<template>
  <div id="CourseAsistencia">
    <b-link @click="add()">Visualizar asistencia</b-link>
    <div>
      <b-modal scrollable centered v-model="show" title="Asistencia">
        <div v-if="desserts != ''">
          <b-container fluid>
            <!--Contenido modal-->
            <b-alert show :variant="absenceColor"
              >Porcentaje de inasistencia: <b>{{ getAbsence() }} %</b>
            </b-alert>
            <b-table
              striped
              responsive
              stacked="lg"
              class="mb-0"
              :items="desserts"
              :fields="fields"
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(date)="row">
                <b-form-datepicker
                  disabled
                  :value="row.item.date"
                ></b-form-datepicker>
              </template>
              <template #cell(score)="row">
                <div v-if="row.item.score == null">-</div>
                <div v-else>{{ row.item.score }}</div>
              </template>
              <template #cell(statusBadge)="row">
                <h5 v-if="row.item.status == null || row.item.color == null">
                  <b-badge pill class="w-100" variant="transparent">
                    Sin asistencia
                  </b-badge>
                </h5>
                <h5 v-else>
                  <b-badge pill class="w-100" :variant="row.item.color">
                    {{ row.item.status }}
                  </b-badge>
                </h5>
              </template>
            </b-table>
          </b-container>
        </div>
        <div v-else class="text-center">
          <em><h4>No hay asistencia</h4></em>
        </div>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseAsistencia",
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      absence: 0,
      absenceColor: "",
      show: false,
      desserts: [],
      fields: [
        {
          key: "index",
          isRowHeader: true,
          label: "#",
        },
        { key: "date", label: "Fecha" },
        //{ key: "score", label: "Puntuación" },
        { key: "statusBadge", label: "Estado" },
      ],
    };
  },
  methods: {
    getAbsence() {
      var cont = 0;
      for (const key in this.desserts) {
        if (this.desserts[key].score == 0) cont++;
      }
      if (cont == 0) {
        this.absenceColor = "success";
        return 0;
      } else {
        var value = (this.desserts.length / cont).toFixed(2);
        if (value >= 30) this.absenceColor = "danger";
        else this.absenceColor = "success";
        return value;
      }
    },
    exit() {
      this.show = false;
    },
    add() {
      this.initialize();
      this.show = true;
    },
    async initialize() {
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/my-assists", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.b-form-btn-label-control.form-control[aria-disabled="true"],
.b-form-btn-label-control.form-control[aria-readonly="true"] {
  background-color: transparent !important;
  border: none;
}
</style>