<template>
  <div id="CourseNotas">
    <b-link @click="add()">Revisar notas</b-link>
    <div>
      <b-modal scrollable centered v-model="show" title="Lista de notas">
        <b-container fluid>
          <!--Contenido modal-->
          <div v-if="desserts != ''">
            <!--Contenido modal-->
            <b-table
              striped
              responsive
              stacked="lg"
              class="mb-0"
              :items="desserts"
              :fields="fields"
            >
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(qualification)="row">
                <p
                  :class="'font-weight-bold mb-0 text-' + row.item.color"
                  v-if="row.item.status != null"
                >
                  {{ row.item.qualification }}
                </p>
                <p class="font-weight-bold mb-0" v-else>-</p>
              </template>
            </b-table>
          </div>
          <div v-else class="text-center">
            <em><h4>No hay notas</h4></em>
          </div>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseNotas",
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      desserts: [],
      fields: [
        {
          key: "index",
          isRowHeader: true,
          label: "#",
        },
        { key: "criteria", label: "Criterio" },
        { key: "qualification", label: "Nota" },
      ],
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    add() {
      this.initialize();
      this.show = true;
    },
    async initialize() {
      await this.$axios
        .get("/dashboard/course/" + this.idCourse + "/my-grades", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>